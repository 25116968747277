@import 'scrollbar';

$modal-border-radius: 5px;
.p-dialog-mask.p-component-overlay {
  background-color: #0006;
}
.p-dialog {
  border-radius: var(--border-radius);
  @include custom-scroll-bar;

  transition: max-width var(--transition), width var(--transition);

  &.help-open {
    width: 100% !important;

    .p-dialog-content {
      padding-right: 0;
      border-radius: var(--border-radius);

      .p-dialog-header {
        .toggle-button {
          border-top-right-radius: var(--border-radius);
        }
      }
    }


  }

  &.import-data-modal .p-dialog-content {
    min-width: 1200px;
    overflow-x: auto;
    position: relative;
  }

  &.import-data-modal,
  &.add-column-modal {
    @media screen and (max-width: 1500px) {
      max-width: 98vw !important;
    }
  }
  &.module-selector {
    max-width: 90%;
    background: var(--df-blue);
    border-radius: 30px;
    min-width: 600px!important;
    &.pack-pro {
      width: 1200px !important;
    }
    .p-dialog-content {
      min-width: 600px!important;
    }
    @media screen and (max-width: 1260px) {
      max-width: 95%;
    }
  }
}

.p-dialog {
  max-height: 95% !important;
  max-width: 1200px;
  background: #fff;

  &.md .p-dialog-content {
    min-width: 600px;
  }
  &.lg .p-dialog-content {
    min-width: 800px;
  }

  &.sm .p-dialog-content {
    min-width: 400px;
  }
  .p-dialog-content {
    border-radius: $modal-border-radius;
    padding: 0 var(--default-layout-padding);
    transition: padding 0.2s ease-in-out;
    overflow: auto;

    @include custom-scroll-bar;

    .dialog-header {
      h4 {
        margin: var(--default-layout-margin) 0;
        font-size: var(--font-size-lg);
      }
      margin-bottom: 1rem;
    }

    .dialog-content {
      &.scrollable-content {
        max-height: 70vh;
        overflow: auto;
        padding: 0 var(--default-layout-padding) var(--default-layout-padding) 0;
        margin-right: -6px;
        @include custom-scroll-bar;
      }
      &.no-title {
        margin-top: calc(var(--default-layout-margin) * 2);
      }
      user-select: text;
      p {
        margin-bottom: var(--default-layout-margin);
      }
    }

    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: var(--default-layout-padding) 0;

      &.left-right {
        justify-content: space-between;
      }

      button {
        margin: 0 0 0 var(--default-layout-margin);
        &.no-left-margin {
          margin-left: 0;
        }
      }
    }
  }
}

.p-dialog-content .dialog-content app-page-panel .grid-form-container:not(.no-padding) {
  padding: var(--default-layout-margin)
}


.units-modal {
  div.modal-dialog {
    max-width: 760px;
  }
}

.scenario-modal {
  div.modal-dialog {
    max-width: 600px;
  }
}

.compare-scenario-modal {
  div.modal-dialog {
    max-width: 400px;
  }
}

.import-temp-profile-modal {
  div.modal-dialog {
    max-width: 400px;
  }
}

.copy-data-modal {
  div.modal-dialog {
    max-width: 400px;
  }
}

.export-results-modal {
  div.modal-dialog {
    max-width: 400px;
  }
}

.chart-config-modal {
  div.modal-dialog {
    max-width: 760px;
  }
}

.scenario-create-modal {
  div.modal-dialog {
    max-width: 400px;
  }
}

.lock-inputs-modal {
  div.modal-dialog {
    max-width: 400px;
  }
}

.scenario-run-simulations-modal {
  div.modal-dialog {
    max-width: 500px;
  }
}

.scenario-manager-loader {
  div.modal-dialog {
    max-width: 500px;
  }
}

.reduce-data-modal {
  div.modal-dialog {
    max-width: 400px;
  }
}

.modal-help {
  width: 0;
  overflow: hidden;
  opacity: 0;
  position: relative;

  margin-left: 0;
  transition: var(--transition);

  .help-content {
    height: 100%;
    position: relative;
    padding-top: 30px;
    padding-bottom: var(--default-layout-padding);
    z-index: 100;
    background: #fff;
    p-accordion {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  iframe {
    border: none;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    width: 273px;
  }

  &.open {
    width: 280px;
    opacity: 1;
    margin-left: var(--default-layout-margin);
    visibility: visible;
    flex: 0 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.generate-report-prompt app-form-input {
  grid-template-columns: auto;
}

app-generic-modal {
  width: 100%;
}
.release-notes-wrapper {
  h1, h2 {
    font-size:  var(--font-size-lg);
    margin: 0 0 10px 0;
  }
  ol {
    margin: 0;
    padding: 1rem;
    li {
      margin-bottom: 1rem;
      ul {
        list-style: disc;
        li {
          margin: 6px 0 0 0;
        }
      }
    }
  }
}


.notification-content-modal {
  width:80vw !important;
  height: 80vh !important;
  .p-dialog-content {
    padding: 0;

    .dialog-content {
      height: calc(100% - 70px);
      position: relative;
    }
    .dialog-footer {
      padding: 1rem;
    }
  }
}
