// Font styles override here

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  font-family: "icon" !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.base-connection-status {
  content: "";
  width: 6px;
  height: 6px;
  right: 11px;
  top: 11px;
  position: absolute;
  z-index: 999;
  border-radius: 50%;
}

.connection-status-connected:before {
  @extend .base-connection-status;
  background: var(--df-blue);
}

.connected-db-disconnected:before {
  @extend .base-connection-status;
  background: var(--warning-color);
}

.connection-status-disconnected:before {
  @extend .base-connection-status;
  background: var(--error-color);
}

.connection-status-request:before {
  @extend .base-connection-status;
  background: var(--warning-color);
  margin-left: -1.4em;
}

.icon-white {
  color: var(--bg-color-0);
}

.icon-light {
  color: var(--icon-light-color);
}

.icon-dark-light-bg {
  color: rgb(25, 21, 48);
}

.icon-notification .path1:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  background: var(--df-orange);
  top: 5px;
  right: 4px;
  border-radius: 50%;
}

.icon-undo.reversed {
  &::before{
    display: block;
    transform: scale(-1, 1);
    padding-right: 16px;
  }
}

// Everything below is generated by 'create-icons.js'. Dont change anything below this comment.
// GENERATED FONT
@font-face {
  font-display: auto;
  font-family: "icon";
  font-style: normal;
  font-weight: 400;
  
  src: url("../fonts/icon.woff2?1737375976912") format("woff2"), url("../fonts/icon.woff?1737375976912") format("woff"), url("../fonts/icon.ttf?1737375976912") format("truetype"); 
}

.icon {
  display: inline-block;
  font-family: "icon";
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.icon-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.icon-xs {
  font-size: 0.75em;
}

.icon-sm {
  font-size: 0.875em;
}

.icon-1x {
  font-size: 1em;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-6x {
  font-size: 6em;
}

.icon-7x {
  font-size: 7em;
}

.icon-8x {
  font-size: 8em;
}

.icon-9x {
  font-size: 9em;
}

.icon-10x {
  font-size: 10em;
}

.icon-fw {
  text-align: center;
  width: 1.25em;
}

.icon-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.icon-pull-left {
  float: left;
}

.icon-pull-right {
  float: right;
}

.icon.icon-pull-left {
  margin-right: 0.3em;
}

.icon.icon-pull-right {
  margin-left: 0.3em;
}


.icon-Calculators::before {
  content: "\ea01";
}

.icon-Data-Analysis::before {
  content: "\ea02";
}

.icon-Evaluate::before {
  content: "\ea03";
}

.icon-PSD-Analysis::before {
  content: "\ea04";
}

.icon-Play::before {
  content: "\ea05";
}

.icon-Simulate::before {
  content: "\ea06";
}

.icon-Trend-Analysis::before {
  content: "\ea07";
}

.icon-Upload::before {
  content: "\ea08";
}

.icon-add-element::before {
  content: "\ea09";
}

.icon-analysis::before {
  content: "\ea0a";
}

.icon-annotation::before {
  content: "\ea0b";
}

.icon-arrow-up-down::before {
  content: "\ea0c";
}

.icon-attachment::before {
  content: "\ea0d";
}

.icon-bar-chart::before {
  content: "\ea0e";
}

.icon-bar-chart-cropped::before {
  content: "\ea0f";
}

.icon-bin-trash-remove-cross::before {
  content: "\ea10";
}

.icon-bottom-chevron::before {
  content: "\ea11";
}

.icon-burger::before {
  content: "\ea12";
}

.icon-business-chart::before {
  content: "\ea13";
}

.icon-chart::before {
  content: "\ea14";
}

.icon-chart-add-plus-square::before {
  content: "\ea15";
}

.icon-chart-analytics-cross-delete-square::before {
  content: "\ea16";
}

.icon-chart-analytics-edit-pen-square::before {
  content: "\ea17";
}

.icon-chart-analytics-modern-circle::before {
  content: "\ea18";
}

.icon-chart-border::before {
  content: "\ea19";
}

.icon-charts-copy::before {
  content: "\ea1a";
}

.icon-checkmark-circle::before {
  content: "\ea1b";
}

.icon-checkmark-circle-filled::before {
  content: "\ea1c";
}

.icon-circle::before {
  content: "\ea1d";
}

.icon-clock::before {
  content: "\ea1e";
}

.icon-completion::before {
  content: "\ea1f";
}

.icon-computing-gear::before {
  content: "\ea20";
}

.icon-copy-file::before {
  content: "\ea21";
}

.icon-cross::before {
  content: "\ea22";
}

.icon-dashboard::before {
  content: "\ea23";
}

.icon-dashboard-style-6::before {
  content: "\ea24";
}

.icon-data-delete::before {
  content: "\ea25";
}

.icon-delete::before {
  content: "\ea26";
}

.icon-delete-disabled::before {
  content: "\ea27";
}

.icon-document-seal::before {
  content: "\ea28";
}

.icon-documents-file-copy::before {
  content: "\ea29";
}

.icon-documents-file-lock::before {
  content: "\ea2a";
}

.icon-documet-copy::before {
  content: "\ea2b";
}

.icon-down-arrow::before {
  content: "\ea2c";
}

.icon-download::before {
  content: "\ea2d";
}

.icon-download-arrow-circle::before {
  content: "\ea2e";
}

.icon-edit::before {
  content: "\ea2f";
}

.icon-enlarge::before {
  content: "\ea30";
}

.icon-enter-circle::before {
  content: "\ea31";
}

.icon-evaluation-guide::before {
  content: "\ea32";
}

.icon-export-data::before {
  content: "\ea33";
}

.icon-eye5::before {
  content: "\ea34";
}

.icon-file::before {
  content: "\ea35";
}

.icon-file-add-plus::before {
  content: "\ea36";
}

.icon-file-blank::before {
  content: "\ea37";
}

.icon-file-sync::before {
  content: "\ea38";
}

.icon-fingerprint-circle-copy::before {
  content: "\ea39";
}

.icon-fluid::before {
  content: "\ea3a";
}

.icon-folder::before {
  content: "\ea3b";
}

.icon-folder-open::before {
  content: "\ea3c";
}

.icon-gradient-line::before {
  content: "\ea3d";
}

.icon-gravel::before {
  content: "\ea3e";
}

.icon-home::before {
  content: "\ea3f";
}

.icon-icon-move::before {
  content: "\ea40";
}

.icon-image-picture-modern-circle-copy::before {
  content: "\ea41";
}

.icon-images-picture-copy::before {
  content: "\ea42";
}

.icon-information::before {
  content: "\ea43";
}

.icon-key-circle::before {
  content: "\ea44";
}

.icon-layers::before {
  content: "\ea45";
}

.icon-left-chevron::before {
  content: "\ea46";
}

.icon-left-right-arrows-circle::before {
  content: "\ea47";
}

.icon-left-triangle::before {
  content: "\ea48";
}

.icon-licensing::before {
  content: "\ea49";
}

.icon-list-add::before {
  content: "\ea4a";
}

.icon-list-delete::before {
  content: "\ea4b";
}

.icon-list-paragraph::before {
  content: "\ea4c";
}

.icon-lock-inputs::before {
  content: "\ea4d";
}

.icon-login::before {
  content: "\ea4e";
}

.icon-logo::before {
  content: "\ea4f";
}

.icon-magic-wand::before {
  content: "\ea50";
}

.icon-measure-ruler::before {
  content: "\ea51";
}

.icon-menu-general::before {
  content: "\ea52";
}

.icon-module-select::before {
  content: "\ea53";
}

.icon-move::before {
  content: "\ea54";
}

.icon-new project::before {
  content: "\ea55";
}

.icon-notification::before {
  content: "\ea56";
}

.icon-pause::before {
  content: "\ea57";
}

.icon-pen::before {
  content: "\ea58";
}

.icon-pen-edit::before {
  content: "\ea59";
}

.icon-pen-edit-undo::before {
  content: "\ea5a";
}

.icon-pencil::before {
  content: "\ea5b";
}

.icon-people::before {
  content: "\ea5c";
}

.icon-people-card::before {
  content: "\ea5d";
}

.icon-pin::before {
  content: "\ea5e";
}

.icon-pin-basket::before {
  content: "\ea5f";
}

.icon-pin-cross::before {
  content: "\ea60";
}

.icon-pin-location-more-dots::before {
  content: "\ea61";
}

.icon-pinned-help::before {
  content: "\ea62";
}

.icon-play-circle::before {
  content: "\ea63";
}

.icon-plus-add::before {
  content: "\ea64";
}

.icon-pumping::before {
  content: "\ea65";
}

.icon-question-mark::before {
  content: "\ea66";
}

.icon-record::before {
  content: "\ea67";
}

.icon-rename::before {
  content: "\ea68";
}

.icon-resize-arrows::before {
  content: "\ea69";
}

.icon-right-chevron::before {
  content: "\ea6a";
}

.icon-save::before {
  content: "\ea6b";
}

.icon-search::before {
  content: "\ea6c";
}

.icon-search-file::before {
  content: "\ea6d";
}

.icon-search-loupe-delete::before {
  content: "\ea6e";
}

.icon-setting-gear::before {
  content: "\ea6f";
}

.icon-share-arrow::before {
  content: "\ea70";
}

.icon-share-arrow-circle-copy::before {
  content: "\ea71";
}

.icon-shrink::before {
  content: "\ea72";
}

.icon-sidemenu-pinned::before {
  content: "\ea73";
}

.icon-signal::before {
  content: "\ea74";
}

.icon-simulate-ch::before {
  content: "\ea75";
}

.icon-speed-dashboard::before {
  content: "\ea76";
}

.icon-survey::before {
  content: "\ea77";
}

.icon-table-edit-chart::before {
  content: "\ea78";
}

.icon-time-loading-back::before {
  content: "\ea79";
}

.icon-tools-settings::before {
  content: "\ea7a";
}

.icon-top-chevron::before {
  content: "\ea7b";
}

.icon-trash-bin-restore-back-up-arrow::before {
  content: "\ea7c";
}

.icon-trashcan::before {
  content: "\ea7d";
}

.icon-undo::before {
  content: "\ea7e";
}

.icon-update-version::before {
  content: "\ea7f";
}

.icon-user::before {
  content: "\ea80";
}

.icon-vacuum-db::before {
  content: "\ea81";
}

.icon-well::before {
  content: "\ea82";
}

