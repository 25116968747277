@import 'theme-common';

.input-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: var(--component-height);

  p-calendar {
    position: relative;
    height: 100%;
    width: 100%;

    > span {
      height: 100%;
      width: 100%;
    }
  }

  input {
    text-align: right;
    border: solid 1px $app-color-bg;
    font-weight: var(--font-weight-bold);
    padding: 0 var(--default-layout-padding);
    width: 100%;
    height: 100%;
    font-size: var(--font-size);
    transition: background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
    border-radius: 20px;
    background: $app-color-bg;
    color: var(--df-blue);

    &:focus {
      outline: none;
      border-color: $app-color;
    }
  }

  &.grid-input {
    height: 100%;

    &.align-left {
      padding-left: 4px;
    }
  }

  &.align-center {
    flex-direction: column;

    input {
      text-align: center;
    }
  }

  &.align-left {
    justify-content: flex-start;

    input {
      text-align: left;
    }
  }

  &.disabled {
    input {
      background-color: var(--light-gray);
      border-color: var(--light-gray);
      color: var(--df-gray);
    }
  }

  &.warning {
    input {
      color: var(--warning-color);
      border-color: var(--warning-color-bg);
      background-color: var(--warning-color-bg);

      &:focus {
        border-color: var(--warning-color);
      }
    }

    &.grid-input {
      background-color: var(--warning-color-bg);
      color: var(--warning-color);
      > span {
        color: var(--warning-color);
      }
    }
  }

  &.error {
    input {
      color: var(--error-color);
      border-color: var(--error-color-bg);
      background-color: var(--error-color-bg);

      &:focus {
        border-color: var(--error-color);
      }
    }

    &.grid-input {
      background-color: var(--error-color-bg);
      color: var(--error-color);
      > span {
        color: var(--error-color);
      }
    }

    .form-component-symbol {
      color: var(--df-blue);
    }
  }
}

.input {
  width: 100%;
}

.grid-input {
  > span {
    font-family: Arial, 'sans-serif';
    font-weight: var(--font-weight-grid);
    top: 0;
    right: 0;
    position: relative;
    padding-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
    color: var(--df-blue);
  }

  > input {
    padding-bottom: 1px;
    font-family: Arial, 'sans-serif';
    font-weight: var(--font-weight-grid);
  }
}



textarea {
  width: 100%;
  border-radius: 2px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  white-space: pre;
  @include custom-scroll-bar;

  &.with-shadow {
    box-shadow: var(--box-shadow);
  }
}
