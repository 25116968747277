@import 'scrollbar';

.p-disabled {
  pointer-events: none;
}
.p-menu {
  .link-error {
    .p-menuitem-link .p-menuitem-text {
      color: var(--error-color) !important;
    }
  }

  .link-warn {
    .p-menuitem-link .p-menuitem-text {
      color: var(--error-color) !important;
    }
  }
}

.left-nav-context-menu {
  margin-left: 0;
  overflow-y: auto;
  max-height: 100%;
  @include custom-scroll-bar;
}
