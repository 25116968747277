.p-contextmenu, .p-submenu-list {
  color: var(--df-blue);
  //font-weight: var(--font-weight-normal);
  border-radius: 9px;
  box-shadow: var(--box-shadow);
  padding: 0;
  background: #fff;

  ul.p-contextmenu-root-list {

    li.p-menuitem {
      .p-menuitem-icon {
        margin-right: 8px;
      }

      a {
        border-radius: 4px;
        padding: 8px;

        anglerighticon {
          height: 22px;
          min-width: 40px;
          text-align: end;

          svg {
            height: 22px;
          }
        }

        &:hover {
          background-color: var(--app-color-hover);
        }
      }
    }
  }

  ul.p-submenu-list {
    min-width: 124px;
    li.p-menuitem {
      a {
        padding: 4px;
      }
    }
  }

  @mixin menuitem-icon($image-url) {
    background-image: url($image-url);
    width: 22px;
    height: 26px;
  }

  .p-menuitem-icon.ppf-file {
    @include menuitem-icon('../images/ppf-file.svg');
  }

  .p-menuitem-icon.fpf-file {
    @include menuitem-icon('../images/fpf-file.svg');
  }

  .p-menuitem-icon.dpf-file {
    @include menuitem-icon('../images/dpf-file.svg');
  }
}
