$tab-height: 32px;

.tabs {
  display: flex;
  flex-direction: row;
  background-color: var(--bg-color-0);
  overflow: auto;
  justify-content: flex-start;
  position: relative;
  margin-bottom: .5rem;
  padding-right: .5rem;
  border-bottom: 1px solid var(--gray);
  .scroll-buttons-container {
    height: $tab-height;
    border: none;
    background-color: var(--bg-color-0);
    font-weight: var(--font-weight-bold);
    cursor: pointer;
    max-width: 120px;
  }

  @include custom-scroll-bar;
}

.tab {
  border-radius: 0 15px 0 0;
  background: #fff;
  font-size: 12px;
  margin-right: 10px;
  transition: background-color var(--transition);
  display: flex;
  align-items:center;
  cursor: pointer;

  a {
    text-decoration: none;
    color: var(--df-blue);
    font-size: 12px;
    padding: 4px 15px 0 15px;
    height: 23px;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }

  &.tab-selected {
    background-color: $app-color-bg;
    a {
      max-width: unset;
    }
  }
}

.tabs {
  .cdk-drag-placeholder {
    border: dotted 2px var(--icon-light-color);
    min-width: 80px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.tab.cdk-drag-preview {
  max-width: 120px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  border-radius: 4px;
    > a {
    padding: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
