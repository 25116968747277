.toggle-button.help-button {
  &.main-help-btn {
    margin-left: -168px;
    width: 168px;
  }

  transition: margin-left var(--transition), width var(--transition), visibility var(--transition), opacity var(--transition);
  position: absolute;
  height: 24px;
  margin-left: -109px;
  width: 109px;
  background-color: var(--df-blue);
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  justify-content: flex-start;
  outline: none;

  border-top-left-radius: var(--border-radius);
  border: none;
  top: -23px;

  .dialog-header & {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: 0;
  }

  .label {
    width: 100%;
    margin: 0 10px;
    text-align: center;
  }

  span {
    font-size: var(--font-size-icon-small);
    margin-right: 0;
  }

  &.toggle-button-open {
    margin-left: 0;
    width: 300px;
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
}

$accordionTabHeight: 36px;
$navHeight: 90px;

.help-notes-accordion {
  .p-accordion-content {
    padding: 0;
  }
}

// accordion may have from 1 to 4 tabs, whenever one tab is open, we want all tabs to be visible
// so content height should be 100% - navbar - tabsQty*tabSize
@for $i from 1 through 5 {
  .tabs-#{$i} {
    .p-accordion-content {
      height: calc(100vh - $navHeight - ($accordionTabHeight * $i));
    }
  }
}

//p-accordion {
//  .ql-toolbar, .ql-container {
//    border-top: none !important;
//    &.ql-show {
//      border:none;
//      border-top: solid 1px;
//    }
//  }
//}