$app-color: #15B7FF;
$app-color-bg: rgba(21,183,255, .1);
$app-color-disabled: rgba(21,183,255, .05);
$app-color-hover: rgba(21,183,255, .15);
$app-color-focus: rgba(21,183,255, .3);

:root {
  --app-color: #{$app-color};
  --app-color-bg: #{$app-color-bg};
  --app-color-hover: #{$app-color-hover};
  --app-color-focus: #{$app-color-focus};
  --app-color-disabled: #{$app-color-disabled};
}
