.p-accordion {

  .p-accordion-header {
    margin: var(--default-layout-margin);
    margin-top: 0;

    background: rgba(153, 153, 153, .1);
    border-radius: 20px;
    height: 24px;
    display: flex;
    align-items: center;
    padding-left: var(--default-layout-padding);
    font-weight: var(--font-weight-bold);

    .p-accordion-header-link {
      width: 100%;

      .p-icon-wrapper {
        margin-right: 5px;
        svg {
          height: 10px;
        }
      }
    }
  }

  .p-accordion-tab {
    &.p-accordion-tab-active {
      .p-toggleable-content {
        padding-bottom: var(--default-layout-margin);
      }
    }
  }

}