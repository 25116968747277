/* You can add global styles to this file, and also import other style files */
$font-size-base: 0.8rem;

@import 'primeicons/primeicons';
@import 'theme';
@import 'icons';
@import 'menu';
@import 'radio-and-checkbox';
@import 'input';
@import 'grid-templates';
@import 'dropdown';
@import 'tabs';
@import 'progress-bar';
@import 'modals';
@import 'date-picker';
@import 'table';
@import 'help-button';
@import 'scrollbar';
@import 'context';
@import 'accordion';
@import 'listbox';
@import 'breadcrumb';
@import 'theme-common';
@import "left-nav";
@import '~quill/dist/quill.snow.css';

$container-border: 1px solid rgba(0, 0, 0, 0.1);

body {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
  background-color: var(--light-gray);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size);
  font-family: var(--font-family), 'sans-serif';
  color: var(--df-blue);
  overflow: clip;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
:after,
:before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

span[class*='icon-'] {
  font-size: var(--font-size-icon);
}

.small-icon {
  font-size: var(--font-size-sm) !important;
}

.smaller-icon {
  font-size: var(--font-size-smaller) !important;
}

$app-page-menu-width: 5px;
$app-page-menu-width_ratio_150: 100px;

input,
select,
textarea,
button {
  font-family: inherit;
}

.p-menu {
  z-index: 2020 !important;
}

*:focus-visible {
  outline: none;
}

.app-page {
  display: flex;
  height: 100%;

  .app-page-menu {
    width: $app-page-menu-width;
    display: flex;
    flex-direction: column;

    .button-stick-bottom {
      margin-top: auto;
    }

    a,
    button {
      display: flex;
      justify-content: center;
      padding: 10px;
      width: #{$app-page-menu-width - 10px};
      text-decoration: none;
      margin-bottom: 10px;
      text-align: center;
      border: solid 1px #ddd;
      border-radius: 4px;
      transition: background-color 200ms ease,
      border-color 200ms ease;

      &:hover {
        background-color: var(--box-shadow);
        cursor: pointer;
      }

      &.active {
        background-color: var(--bg-color-0);
        border: solid 1px var(--icon-light-color-hover);
      }
    }
  }

  .app-page-content {
    width: 100%;
    height: 100%;
  }
}

.dropdown-menu {
  &.show {
    z-index: 1001;
  }
}


html,
body {
  overflow-x: clip;
  @media screen and (max-width: 1179px) {
    @include custom-scroll-bar;
    &.login-form {
      min-width: 300px;
    }
  }
}

.error:not(label) {
  color: var(--error-color);
}


.disabled, .p-disabled {
  pointer-events: none;
  cursor: default;
  color: $app-color-disabled;
  opacity: 1 !important;
}


.regular-tooltip, .p-tooltip {
  border: 1px solid var(--df-blue);
  padding-left: 10px;

  .p-tooltip-text {
    color: var(--df-blue);
  }

  &:before {
    display: none;
    background-color: var(--df-blue);
  }
}


.p-tooltip {
  pointer-events: none;
  padding-left: 10px;
  min-height: 24px;

  border-radius: 0 20px 20px 0;
  background: #fff;
  padding-right: .5rem;

  .p-tooltip-text {
    display: flex;
    align-items: center;

    padding: 3px 0;
    white-space: pre-wrap;
    font-weight: var(--font-weight-bold);
  }

  &.error-tooltip {
    border: 1px solid var(--error-color);
    padding-left: 30px;

    .p-tooltip-text {
      color: var(--error-color);
    }

    &:before {
      background-color: var(--error-color);
      content: "X";
    }
  }

  &.warning-tooltip {
    border: 1px solid var(--warning-color);
    padding-left: 30px;

    .p-tooltip-text {
      color: var(--warning-color);
    }

    &:before {
      background-color: var(--warning-color);
      content: "i";
    }
  }

  &:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    height: 100%;
    color: #fff;
    width: 24px;

    padding-right: 3px;
    border-radius: 0 20px 20px 0;

    .tooltip-right & {
      border-radius: 0 20px 20px 0;
    }
  }
}


.edit-port-data-tooltip {
  @extend .error-tooltip;
  margin-left: 30px;
}

.buttons-panel {
  justify-content: space-between;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: end;

  > div {
    button {
      margin-left: 5px;
      margin-right: 0;
    }

    app-button:first-child {
      button {
        margin-left: 0;
      }
    }
  }
}

.base-issue-row-icon {
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;
  top: 4px;
  text-align: center;
  z-index: 99;

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    line-height: 20px;
    font-family: 'Geologica', sans-serif;
    font-size: 9.5px;
  }
}

.error-row-icon {
  background: var(--error-color);
  border: solid 1px white;
  @extend .base-issue-row-icon;

  &:before {
    content: 'X';
    line-height: 15px;
  }
}

.warning-row-icon {
  background: var(--warning-color);
  border: solid 1px white;
  @extend .base-issue-row-icon;

  &:before {
    content: '!';
    color: white;
  }
}

.df-dialog {
  .modal-content {
    height: 400px;
    overflow: auto;
    word-break: break-word;
    padding: 10px;
  }
}

.error-panel {
  display: flex;
  height: 50%;
  align-items: center;
  justify-content: center;
  color: var(--error-color);
}

.form-component-symbol {
  width: auto;
  flex: 0 0 auto;
  padding-left: var(--input-symbol-padding);
  box-sizing: initial;
  min-width: 60px;

  span {
    white-space: nowrap;
    vertical-align: middle;
  }
}

.centered-message {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
  align-items: center;
}

.no-border {
  border: none !important;
}

div.disabled-content {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
}

div.activity-overlay-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-page-menu {
  .error-row-icon {
    right: -5px;
    top: -5px;
    position: absolute;
  }

  a {
    position: relative;
  }

  div.break {
    height: 20px;
  }
}

.drop-zone {
  height: 100% !important;
  width: 100%;
  z-index: 1000 !important;
  opacity: 0.8;
  background-color: var(--bg-color-3) !important;
}

.drop-zone-content {
  height: 100% !important;
  display: flex;
  justify-content: center;
  z-index: 1000;
  align-items: center;
}

hr {
  margin: 0 var(--default-layout-margin);
  border: none;
  border-left: var(--container-border-light);
}

.p-hidden {
  display: none;
}

.grid-outline-container {
  box-shadow: var(--shadow);
  border: solid 1px var(--black-lowest-opacity);
}

cdk-virtual-scroll-viewport {
  @include custom-scroll-bar;
}

.dev-panel {
  position: fixed;
  width: 312px;
  height: 100%;
  top: 65px;
  background: white;
  opacity: 0.9;
  right: 0;
  overflow: auto;
  z-index: 1000;
  border-left: solid;
  padding: var(--default-layout-padding);
}

.default-help {
  line-height: 12px;

  p {
    color: #000;
    font-size: 13px;
    margin: 0.2rem 0;
    line-height: 14px;
  }

  h4 {
    font-size: 14px;
    color: #000;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.data-import-help-accordion p-accordiontab .p-accordion-content {
  height: 662px;
  padding: 0;
}

.tabs-2 .data-import-help-accordion p-accordiontab .p-accordion-content {
  height: 641px;

}

.p-accordion-header-link:focus {
  box-shadow: none !important;
}

.icon-logo {
  color: var(--app-icon-color);
}

.options-panel {
  display: none;
}

.row-selected {
  .options-panel {
    display: block !important;
  }
}

a,
button,
.btn {
  outline: none !important;

  &:focus,
  &:focus-visible {
    box-shadow: none;
    outline: none !important;
  }
}

.recording * {
  pointer-events: none;

  button {
    opacity: 0.3;
  }

  .slider-wrapper .btn-wrapper * {
    pointer-events: all;
  }
}

.orange-bullet {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: var(--df-orange);
  border-radius: 2px 20px 20px 0;
  margin-right: 6px;
}

b.filter {
  font-weight: var(--font-weight-bold);
}

.text-center {
  text-align: center;
}


.ql-editor {
  @include custom-scroll-bar;
  padding: var(--default-layout-padding);
}

.ql-toolbar.ql-snow {
  border-top: none;
}

.ql-container.ql-snow {
  border-bottom: none;
}

.ql-editor {
  border: none;
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.no-animation {
  app-files-list-file, app-page-panel, .tabs .tab {
    animation: none !important;
    opacity: 1 !important;
  }
}

.panel-full-screen {
  // when any panel is in fullscreen mode - hide all other panels
  app-page-panel:not(.full-screen) {
    display: none;
  }

  // do not hide panels inside fullscreen panel
  app-page-panel.full-screen {
    app-page-panel {
      display: inline-flex;
    }
  }

  app-reporting-range-selection p-listbox .p-listbox-list-wrapper {
    height: calc(100vh - 345px);
  }
}
app-reporting-range-selection p-listbox .p-listbox-list-wrapper {
  height: calc(100vh - 555px);
  min-height: 282px;
}

.time-format-suggestion {
  font-size: var(--font-size-small);
  margin-right: var(--default-layout-margin);
  cursor: pointer;
  transition: background var(--transition);
  background: var(--gray);
  border-radius: 20px;

  &:hover,
  &.active {
    background: $app-color-bg;
  }

  .p-chip-text {
    padding: 0 10px;
  }
}

.user-profile-menu {
  width: 100px;
}

.highlight {
  background-color: var(--warning-color);
}

.mt-1 {
  margin-top: var(--default-layout-margin);
}
.mb-1 {
  margin-bottom: var(--default-layout-margin)
}
.pt-1 {
  padding-top: var(--default-layout-margin);
}
.pb-1 {
  padding-bottom: var(--default-layout-margin)
}
