.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  grid-gap: var(--default-layout-grid-gap);
}

.grid-form-container {
  @extend .grid-container;
  grid-template-columns: auto 1fr;
  grid-gap: var(--default-layout-grid-gap-row) var(--default-layout-grid-gap-column);
  align-items: center;
  justify-items: flex-start;
  width: 100%;

  > * {
    margin-right: var(--default-layout-margin);
  }

  .dummy-input {
    height: var(--component-height);
    margin-bottom: var(--default-layout-margin);
  }

  &.main{
    grid-template-columns: 400px 400px;
  }


  .align-top {
    align-self: flex-start;
  }

  .input {
    max-width: calc(var(--input-width) + var(--input-symbol-padding) + var(--input-symbol-min-width));
  }

  &.inline {
    display: inline-grid;
  }

  &.no-padding {
    padding: 0;
  }

  &.no-padding-horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  &.no-padding-vertical {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.padding-right {
    padding-right: var(--default-layout-padding);
  }

  &.cols-1 {
    grid-template-columns: auto;
  }

  &.cols-2 {
    grid-template-columns: min-content minmax(min-content, 300px);
  }

  &.cols-3 {
    grid-template-columns: auto auto auto;
  }

  &.cols-4 {
    grid-template-columns: min-content minmax(min-content, 300px) min-content minmax(min-content, 300px);
  }

  &.cols-4-cross-aligned {
    grid-template-columns: 75px minmax(min-content, 300px) 75px minmax(min-content, 300px);
  }

  &.cols-6 {
    grid-template-columns: min-content minmax(min-content, 300px) min-content minmax(min-content, 300px) min-content minmax(
        min-content,
        300px
      );
  }

  &.cols-7 {
    grid-template-columns: auto auto auto auto auto auto auto;
  }

  &.cols-8 {
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }

  .col-span-2 {
    grid-column: span 2;
  }

  .col-span-3 {
    grid-column: span 3;
  }

  .col-span-4 {
    grid-column: span 4;
  }

  .group {
    display: flex;
    align-items: center;
    &.margin-right {
      margin-right: var(--default-layout-margin);
    }

    .margin-right {
      margin-right: var(--default-layout-margin);
    }
  }

  > label {
    display: inline-block;
    margin-bottom: 0;
    white-space: nowrap;
    margin-right: 0;
  }
}

.grid-double-form-container {
  @extend .grid-form-container;
  grid-template-columns: 1fr 1fr;
  width: 100% !important;

  .grid-form-container {
    padding: 0;
  }
}

.grid-one-one {
  grid-column: span 1;
  grid-row: span 1;
}

.grid-one-two {
  grid-column: span 1;
  grid-row: span 2;
}

.grid-one-three {
  grid-column: span 1;
  grid-row: span 3;
}

.grid-two-one {
  grid-column: span 2;
  grid-row: span 1;
}

.grid-two-two {
  grid-column: span 2;
  grid-row: span 2;
}

.grid-three-one {
  grid-column: span 3;
  grid-row: span 1;
}

.grid-four-one {
  grid-column: span 4;
  grid-row: span 1;
}

.grid-six-one {
  grid-column: span 6;
  grid-row: span 1;
}

.container-full {
  width: 100% !important;
}

.row-span-2 {
  grid-row: span 2;
}

.row-span-3 {
  grid-row: span 3;
}

.row-span-4 {
  grid-row: span 4;
}

// options page

app-settings app-page-panel .app-panel-content{
    .grid-form-container {
      grid-template-columns: 210px 300px;
    }
}
