@import 'scrollbar';

app-grid-select {
  app-select {
    .p-inputtext {
      font-size: var(--grid-font-size);
      color: var(--df-blue);

    }

    chevrondownicon {
      right: 0;
    }
  }
}

p-dropdown.some-items-invalid-error  {
  .p-dropdown {
    border: 1px solid var(--error-color);
  }
}

.p-inputtext {
  padding: 4px 10px;
}

.p-dropdown,
.p-multiselect,
.p-dropdown-panel,
.p-multiselect-panel,
.p-menu {
  background: #fff;
  padding: 0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  width: 100%;
  border-radius: var(--border-radius);
  border: solid 1px transparent;
  font-weight: var(--font-weight-bold);

  .p-multiselect-label,
  .p-dropdown-label {
    display: flex;
    align-items: center;
    max-width: calc(100% - 10px);
  }

  &.p-inputwrapper {
    border: solid 1px $app-color-bg;
  }

  &:not(.p-disabled).p-focus,
  &.p-inputwrapper-focus {
    outline: none;
    box-shadow: none;
    border-color: $app-color;
  }

  &.p-menu-default {
    max-width: 150px;
  }

  .p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .p-dropdown-items,
  .p-multiselect-items,
  .p-menu-list {
    .error-row-icon {
      margin-right: 4px;
      top: 2px;
    }

    .p-menuitem,
    .p-dropdown-item,
    .p-multiselect-item {
      padding: var(--default-layout-padding);
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        background: $app-color-hover;
      }

      .p-menuitem-content {
        a {
          height: 14px;
        }
      }
    }
  }
}

.p-dropdown,
.p-multiselect {
  height: var(--component-height);
  background: $app-color-bg;

  .error & {
    background: var(--error-color-bg);
    span {
      color: var(--error-color);
    }
  }
}

.p-multiselect-panel {
  .p-checkbox,
  .p-checkbox-box {
    width: 1rem;
    height: 1rem;

    .p-checkbox-icon {
      display: block;
      font-weight: var(--font-weight-bold);
    }
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background: $app-color;

    checkicon {
      color: #fff;

      svg {
        height: 12px;
        width: 12px;
        top: 1px;
        left: 1px;
        position: relative;
      }
    }
  }

  .p-checkbox .p-checkbox-box.p-highlight,
  //.p-checkbox .p-checkbox-box.p-highlight:hover
  {
    border-color: $app-color;
  }
}

.p-dropdown-items-wrapper,
.p-multiselect-items-wrapper {
  @include custom-scroll-bar;
}

.p-dropdown-label div {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);

}


// search filter

.p-dropdown-filter-container {
  .p-dropdown-filter {
    border-radius: var(--border-radius);
    background: $app-color-bg;
    border: solid 1px $app-color;
  }
}

p-dropdown.file-search-dropdown {
  width: 500px;
  flex: 0 0 auto;
  height: 44px;

  .p-dropdown-label {
    padding-left: 45px;
  }

  .p-overlay {
    margin-top: 1px !important;
  }


  .p-dropdown {
    height: 44px;
    border-radius: var(--border-radius);
    padding-right: 1rem;
  }

  .p-dropdown-items-wrapper {
    margin-right: -6px;

  }

  chevrondownicon {
    margin-top: 4px;
  }

  .p-placeholder {
    display: flex;
    align-items: center;
    padding-left: 44px;
    color: var(--df-gray);
    font-weight: var(--font-weight-bold);
  }

  searchicon {
    display: none;
  }

  .p-overlay {
    max-width: 100%;
  }

  .p-dropdown-items {
    padding-top: .5rem;
  }


  .p-dropdown-header {
    background: var(--light-gray);
  }

  .p-dropdown-filter-container {
    input {
      border-radius: 20px;
      height: 22px;
      //font-size: var(--font-size);
      background: $app-color-bg;
      border: none;
      box-shadow: none;
    }
  }
}

.p-multiselect-panel,
.p-dropdown-panel, .p-menu {
  border-radius: var(--border-dadius-small);
  box-shadow: var(--box-shadow);
}

.p-multiselect-items {
  .p-multiselect-item {
    .p-checkbox {
      margin-right: .5rem;

      .p-checkbox-box {
        &.p-highlight {
          background: $app-color;

          checkicon {
            color: #fff;

            svg {
              height: 12px;
              width: 12px;
              top: 1px;
              left: 1px;
              position: relative;
            }
          }
        }
      }
    }
  }
}

.p-dropdown-empty-message,
.p-multiselect-empty-message {
  padding: var(--default-layout-padding);
}

p-multiselect, p-dropdown {
  height: var(--component-height);
  width: 100%;
  display: block;
}

p-multiselect {
  .p-multiselect {
    background-color: #fff;

    &.p-disabled {
      background-color: var(--light-gray) !important;
      opacity: 1;

      .p-dropdown-label, .p-multiselect-label {
        color: var(--df-gray) !important;

        padding-left: 10px;
      }
    }

    .p-multiselect-label {
      padding-left: var(--default-layout-padding);
    }
  }

  &.blue-bg {
    .p-multiselect {
      background-color: $app-color-bg;
    }
  }

  &.error {
    .p-multiselect {
      color: var(--error-color);
    }
  }
}

.p-multiselect-panel {
  .p-multiselect-header {
    padding: var(--default-layout-padding);
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;

    .p-checkbox {
      margin-right: .5rem;
    }

    .p-multiselect-close {
      margin-left: .5rem;
      background: none;
      border: none;
    }

    searchicon {
      position: absolute;
      right: 22px;
      top: 12px;
    }

    input {
      border-radius: 26px;
      background: $app-color-bg;
      color: var(--df-blue);
      border: none;
    }
  }
}

p-multiSelect, app-select {
  chevrondownicon {
    right: 6px;
    top: 11px;
    position: absolute;
    transform: translateY(-50%);
    height: 14px;
  }
}

.disabled {
  p-multiSelect, app-select, p-dropdown {
    p-dropdown {
      background-color: $light-gray !important;

      span {
        color: var(--df-gray) !important;
      }
    }
  }
}

.p-dropdown.p-disabled {
  background-color: $light-gray !important;
  border-color: $light-gray !important;
}

.ribbon-select {
  .p-dropdown {
    background-color: #fff !important;
  }
}

.ribbon-input {
  .input-container input {
    background-color: #fff !important;
  }
}


.ribbon-select {
  &.disabled {
    background-color: var(--light-gray) !important;
    color: var(--df-gray) !important;
  }
}
