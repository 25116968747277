.p-progressbar-determinate .p-progressbar-value-animate {
  transition: none !important;
}

.p-progressbar .p-progressbar-value {
  background: var(--df-blue);
}

.p-progressbar .p-progressbar-label {
  color: #fff
}
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 20px;
}