p-listbox {
  .p-listbox-item-group {
    label {
      font-weight: var(--font-weight-bold);
    }
  }

  .p-listbox-item {
    .p-checkbox-box {
      margin-right: .5rem;
      &.p-highlight {
        checkicon {
          color: #fff;
          svg {
            height: 12px;
            width: 12px;
            top:1px;
            position: relative;
          }
        }

        border-color: $app-color;
        background-color: $app-color;
        border-width: 1px;
      }
    }
  }
}


app-filterable-tree {
  .group, .p-listbox-item .item-label{
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
