$subMenuIdent: 49px;
$leftMenuBorderRadius: 0 35px 0 0;

app-left-nav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 600;
}

app-left-nav-item {
  display: block;
}

.left-nav-container {
  display: grid;
  grid-template-rows: $app-header-height 1fr;
  height: 100vh;
  position: relative;
  width: $main-sidebar-width-closed;
  transition: width var(--transition);

  .logo-wrapper {
    height: $app-header-height;
    display: flex;
    align-items: center;
    width: $main-sidebar-width-opened;
    margin-left: -13px;

    svg {
      height: 45px;
      margin-left: 2px;
      transition: var(--transition);

      * {
        transition: var(--transition)
      }

      path {
        opacity: 0;
        transform: translateY(10px);
      }

      &.pinned {

        path {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  .open-close-btn {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 71px;
    right: 25px;
    transition: opacity var(--transition);
    span {
      font-size: var(--font-size-icon-small);
      color: #fff;

    }

    &:hover,
    &.active {
      span {
        color: #fff
      }
    }

    &.active {
      span {
        color: var(--df-orange)
      }
    }
  }

  .left-nav {
    width: $main-sidebar-width-closed;
    transition: width var(--transition);
    background: var(--df-blue);
    border-radius: $leftMenuBorderRadius;
    box-shadow: var(--box-shadow);
    padding-top: 10px;
    padding-left: 0;
    overflow: hidden;

    .scrollable {
      @include custom-scroll-bar;
      margin-top: 21px;
      height: calc(100vh - 192px);
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      display: grid;
      grid-template-rows: auto 1fr 64px;

      .scrollable-buttons-container {
        padding-right: 6px;

      }

      .scrollable-shadow {
        position: fixed;
        height: 10px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        z-index: 700;
        opacity: 0;
        transition: opacity var(--transition), width var(--transition);
        width: #{$main-sidebar-width-closed - 11};

        &.open {
          width: #{$main-sidebar-width-opened - 11};

        }

      }

      &.scroll-up-possible {
        .scrollable-shadow {
          opacity: 1;
        }
      }

      .start-stop-button-container {
        position: relative;
        padding: 10px 0 10px 13px;

        .start-stop-button {
          height: 30px;
          width: 30px;
          border-radius: 47px;
          background-color: var(--df-orange);
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 5px;
          padding-top: 3px;
          color: #fff;
          margin-left: 10px;
          position: relative;

          span {
            font-size: 15px;
            position: relative;
            left: -1px;
            bottom: 2px;
          }

          &.stop-button {
            span {
              font-size: 22px;
              bottom: 4px;
              left: -2px;
            }
          }
        }

        &:hover {
          background-color: var(--app-color-hover) !important;

          .start-stop-button {
            cursor: pointer;
          }
        }

        &.disabled {
          pointer-events: none;

          .start-stop-button {
            background-color: var(--gray)
          }
        }

        .main-label {
          position: relative;
          left: 10px;
          top: 9px;
          transform: translateY(-50%);
          color: var(--df-blue);
          margin: 0;
          white-space: nowrap;
          opacity: 0;
          text-transform: capitalize;
          font-size: var(--font-size-lg);
        }

        &.extended {
          padding-left: 0;

          .main-label {
            opacity: 1;
            visibility: visible;
          }
          .loader-circle {
            left: 6px;

          }
        }

        .loader-circle {
          position: absolute;
          left: 19px;
          top: 4px;
          transition: left var(--transition);

          circle {
            transform: rotate(-90deg);
            transform-origin: center;
            transition: stroke-dashoffset .5s ease-in-out;
          }
        }
      }

      .white-section {
        background: #fff;
        border-radius: $leftMenuBorderRadius;
        padding: 10px 0;

        app-left-nav-item {
          padding-right: 3px;
        }

        app-start-stop-button {
          margin-right: 8px;
          display: block;
        }

        .left-nav-links-single {
          color: var(--df-blue);
          margin-right: 6px;
        }

        .sub-menu {
          .sub-menu-item {
            a.sub-menu-link {
              color: var(--df-blue);
              width: 158px;
            }
          }
        }


        &.one-item {
          border-radius: 0 50px 50px 0;
        }
      }

      .white-fill {
        background: white;
        margin-right: 6px;
      }
    }

    &-links {
      transition: width var(--transition);

      &.spacer {
        pointer-events: none;
      }

      &-single {
        text-decoration: none;
        color: #fff;
        border-radius: 0 100px 100px 0;
        background: transparent;
        cursor: pointer;
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: var(--font-size-lg);
        transition: color var(--transition), background-color var(--transition), padding-left var(--transition);
        padding-left: 27px;
        margin-right: 0;

        .icon {
          margin-right: 12px
        }


        &:hover {
          background: var(--app-color-hover);
        }

        &.active {
          background: var(--app-color);
        }

        .icon-wrapper {
          font-size: var(--font-size-icon);

          .nav-error-icon {
            position: absolute;
            top: auto;
            margin-top: -5px;
            left: 44px;
            transition: left var(--transition);
            box-shadow: var(--box-shadow);

            &.warning-row-icon {
              margin-top: -3px;
            }
          }
        }

        .main-label {
          transition: opacity var(--transition);
          white-space: nowrap;
          opacity: 0;
        }
      }
    }
  }

  .logo-footer {
    display: block;
    background: var(--df-blue);
  }

  &.home-page-mode {
    .left-nav {
      .left-nav-links {
        &.home {
          width: 218px;
        }
        .scrollable {
          grid-template-rows: 1fr 64px;
          height: calc(100vh - 140px);
        }
      }
    }

    .open-close-btn {
      display: none;
    }

    app-left-nav-df-logo {
      svg {
        margin-left: 11px;
      }
    }
  }

  &.open, &.home-page-mode {
    width: $main-sidebar-width-opened;

    .logo-wrapper {
      width: $main-sidebar-width-opened;
    }

    .left-nav {
      box-shadow: $box-shadow-expanded;
    }

    .open-close-btn {
      opacity: 1;
      //visibility: visible;
    }

    .left-nav {
      width: $main-sidebar-width-opened;
      padding-right: 5px;

      &-links {
        width: auto;

        &.home {
          width: 160px;
        }

        &-single {
          padding-left: 15px;

          &.active {
            color: white!important;
          }

          .main-label {
            opacity: 1;
          }

          .icon-wrapper {
            .nav-error-icon {
              left: 32px;
            }
          }

          &.disabled {
            color: var(--dark-gray);
          }


        }
      }
    }
  }
}

.sub-menu {
  list-style: none;
  padding: 0 0 0 $subMenuIdent;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition), opacity var(--transition);
  opacity: 0;

  &.opened {
    opacity: 1;
    margin-bottom: var(--default-layout-margin);
  }

  .cdk-drag-placeholder {
    border: dotted 2px var(--icon-light-color);
    min-height: 30px;
    margin-right: 0.5rem;
  }
}


.sub-menu-item {
  // this can't be nested in .sub-menu because of drag&drop
  user-select: none;

  .sub-menu-link {
    padding-top: 8px;
    padding-bottom: 8px;
    text-decoration: none;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    color: #fff;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      height: 1px;
      width: 0;
      bottom: 4px;
      left: 0;
      position: absolute;
      transition: width var(--transition);
      background-color: #fff;
    }

    &.p-disabled {
      color: var(--dark-gray)!important;
    }

    &.active, &:hover {
      &::after {
        width: 100%;
      }
    }

    &.active {
      font-weight: 700
    }
  }

  &:first-of-type {
    padding-top: 0;
  }

  &.cdk-drag-preview {
    padding-bottom: 0;
    display: block;
    background: var(--bg-color-3);
    list-style: none;
    opacity: 1;

    a {
      padding-left: 5px;
      overflow: hidden;
    }

    .sub-menu-link {
      color: var(--left-menu-item-accent-select) !important;
    }
  }


  &.link-error a {
    color: var(--error-color) !important;

    &::after {
      background-color: var(--error-color) !important;
    }
  }

  &.link-warn a {
    color: var(--warning-color) !important;

    &::after {
      background-color: var(--warning-color) !important;
    }
  }
}


