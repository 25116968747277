@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');

$df-blue: #020253;
$df-orange: #ff5c00;
$df-gray: #B3B1B1;

$light-gray: #F5F5F5;
$gray: #D9D9D9;
$dark-gray: #999999;



$app-color: #15B7FF;
$app-color-bg: rgba(21,183,255, .1);
$app-color-disabled: $dark-gray;
$app-color-disabled-bg: rgba(0, 0, 0, 0.03);;
$app-color-hover: rgba(21,183,255, .15);
$app-color-focus: rgba(21,183,255, .3);
$app-color-warning: #fcba03;



$default-margin: 10px;


$red: #FF0000;
$violet: #B759DA;

$font-family: 'Geologica';

$transition: .2s ease-in-out;
$box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.25);
$box-shadow-expanded: 2px 4px 6px 1px rgba(0, 0, 0, 0.25);
$component-height: 24px;

$main-sidebar-width-opened: 230px;
$main-sidebar-width-closed: 80px;

$app-header-height: 50px;

:root {
  --font-family: #{$font-family};
  --font-size-small: 0.6rem;
  --font-size: 0.75rem;
  --font-size-lg: .9rem;
  --font-size-grid: 0.75rem;
  --font-size-icon: 22px;
  --font-size-icon-small: 16px;

  --font-weight-normal: normal;
  --font-weight-bold: 500;
  --font-weight-grid: 600;

  --breacrumb-height: 20px;
  --page-panel-header-height: 34px;
  --component-height: 24px;
  --big-button-height: 80px;

  --input-width: 200px;
  --input-symbol-min-width: 40px;
  --input-symbol-padding: 10px;
  --left-shrink: 160px;
  --min-width: 953px;

  --df-blue: #{$df-blue};
  --df-orange: #{$df-orange};
  --df-gray: #{$df-gray};

  --light-gray: #{$light-gray};
  --gray: #{$gray};
  --dark-gray: #{$dark-gray};

  --error-color: #{$red};
  --error-color-bg: rgba(254, 40, 87, .1);
  --warning-color: #F2A13A;//#{$app-color-warning};
  --warning-color-bg: rgba(242, 161, 58, .1);

  --main-sidebar-width: 230px;

  --transition: #{$transition};
  --box-shadow: #{$box-shadow};

  --border-radius: 20px;
  --border-dadius-small: 10px;
  --border: 1px solid var(--gray);
  --default-layout-grid-gap: #{$default-margin};
  --default-layout-grid-gap-row: #{$default-margin};
  --default-layout-grid-gap-column: #{$default-margin};
  --default-layout-margin: #{$default-margin};
  --default-layout-double-margin: 20px;
  --default-layout-padding: #{$default-margin};
  --default-layout-double-padding: 20px;
}
