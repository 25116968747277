@mixin header-font {
  font-weight: var(--font-weight-bold);
  font-family: 'Geologica', sans-serif;
  color: var(--df-blue);
  font-size: var(--font-size) !important;
}


table:not(.with-selection-col):not(.p-datepicker-calendar) {
  th:nth-of-type(2),
  td:nth-of-type(2) {
    border-left: 0 !important;
  }
}

app-grid {
  &.border-top {
    table th {
      border-top: solid 1px var(--gray);
    }
  }

  &.no-border-col-1 {
    th:nth-of-type(1),
    td:nth-of-type(1) {
      border-left: none;
    }
  }

  &.no-border-col-2 {
    th:nth-of-type(2),
    td:nth-of-type(2) {
      border-left: none;
    }
  }
}


table {
  table-layout: fixed !important;
  width: 100%;
  font-family: 'Arial', sans-serif;
  border: none;
  border-collapse: collapse;

  &.with-selection-col.no-head tr.mat-header-row,
  &.with-selection-col.no-head tr.mat-mdc-header-row {
    height: 0 !important;
    overflow: hidden;

    th {
      overflow: hidden;
      height: 0 !important;

      * {
        height: 0 !important;
      }
    }
  }

  th,
  td {
    user-select: none;
    border-left: 1px solid var(--gray);
    border-bottom: none !important;
    font-size: var(--font-size-grid) !important;
    font-weight: var(--font-weight-grid);

    &.group-header {
      text-align: left !important;
      padding: 5px 2px !important;

      span {
        margin-right: 6px;
        margin-left: 4px;
      }
    }

    span {
      white-space: nowrap;
    }

    &.disabled {
      span {
        color: var(--dark-gray) !important;
      }

      &:not(.selected) {
        background-color: $app-color-disabled-bg;
      }
    }

    // this is for selection column
    &:first-of-type,
    &:first-of-type {
      display: none;
    }
  }

  th {
    vertical-align: top;
    background: white;
    //border-left: none !important;
  }

  th:first-child {
    border-left: none !important;
  }

  th:after,
  th:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
  }

  th:before {
    top: -1px;
  }

  th:after {
    bottom: -2px;
  }

  tr.mat-row,
  tr.mat-mdc-row,
  tr.mat-footer-row,
  tr.mat-mdc-footer-row {
    height: 20px !important;
    line-height: 20px;
  }

  .mat-row,
  .mat-mdc-row,
  .mat-header-row,
  .mat-footer-row,
  .mat-mdc-header-row,
  .mat-mdc-footer-row {
    border-bottom-style: none;
  }

  td {
    width: 100px;
    padding: 5px;

    .mat-icon,
    .mat-mdc-icon {
      display: inline-flex;
      height: 27px;
      vertical-align: middle;
    }
  }

  td:after {
    user-select: all;
  }

  td.selection-col {
    width: 25px;
  }

  tr.mat-header-row,
  tr.mat-mdc-header-row {
    height: 16px !important;
  }

  &.with-selection-col {
    th:first-child {
      display: table-cell;
      width: 20px;
    }

    td.mat-cell:first-of-type,
    td.mat-mdc-cell:first-of-type {
      text-align: center;
      display: table-cell;

      &.group-header {
        text-align: left !important;
      }
    }

    tr.mat-header-row,
    tr.mat-mdc-header-row {
      height: 32px !important;
    }
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell,
  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    padding: 0 0 !important;
  }

  td, th, {
    &.disabled {
      pointer-events: all;
    }
  }
}

.selection-col {
  padding-left: 0 !important;
  &.error-row-icon {
    border-color: var(--error-color);
    &:before {
      line-height: 20px;
    }
  }
  &.warning-row-icon {
      border-color: var(--warning-color);
      &:before {
        line-height: 20px;
      }
    }
}

.table thead th {
  vertical-align: top;

  @include header-font;

  span {
    @include header-font;
  }
}

.table-sm th {
  padding: 0;
  vertical-align: auto;
}

.grid-viewport {
  width: 100%;
}


.hidden {
  display: none;
}

.not-input {
  color: black !important;
  background: inherit !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  text-align: center !important;
  line-height: 39px;
}

.top-header {
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-checkbox .custom-control-label {
    &:before {
      margin-top: 0;
      left: 0;
    }

    position: initial;
    width: 100%;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    left: 6px;
  }

  > span {
    padding-bottom: 4px;
    text-align: center;
    line-height: 12px;
  }
}

.bottom-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;

  > span {
    padding-bottom: 4px;
  }
}

.alternate {
  td {
    background-color: $app-color-bg;

    &.error-cell, &.warning-cell {
      background-color: white;
    }
  }
}

.selected,
.mat-mdc-cell.selected {
  background-color: $app-color-focus !important;
  .input-container.warning.grid-input,  .input-container.error.grid-input {
    background-color: inherit;
  }
}


.button-row {
  display: flex;
  flex-direction: row;
}

.context-menu {
  position: absolute;

  > div {
    box-shadow: 10px 10px 24px -16px rgba(0, 0, 0, 0.79);
  }
}


p-listbox .p-listbox-list-wrapper {
  @include custom-scroll-bar;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
  padding: 0;
  padding-left: var(--default-layout-padding);
  padding-top: var(--default-layout-padding);

}

.p-listbox .p-listbox-list .p-listbox-item {
  padding: calc(var(--default-layout-padding) * 0.5) calc(var(--default-layout-padding) * 2);

  .item-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.p-listbox-empty-message {
  padding: 1rem;
}

p-listbox.reorder {
  .p-listbox-item {
    display: none;
  }
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
