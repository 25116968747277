@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--dark-gray);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--df-orange);
  }

  &::-webkit-scrollbar-thumb:active {
    background: var(--df-orange);
  }
}
