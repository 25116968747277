@import 'theme-common';

.custom-control-label:before,
.custom-file-label,
.custom-select,
p-checkbox .p-checkbox-box {
  transition: background-color 0.2s ease-in-out,
  border-color 0.2s ease-in-out,
  box-shadow 0.2s ease-in-out;
}


// Checkbox


.custom-checkbox {
  display: flex;
}


.highlight:not(label) {
  background-color: var(--warning-color);

  .custom-control-label:before {
    border-color: var(--warning-color);
  }

  .custom-control-input:checked ~ .custom-control-label:after {
    background-color: var(--warning-color);
  }

  .custom-control-input:checked ~ .custom-control-label:before {
    border-color: var(--warning-color)
  }
}

.p-checkbox {
  .p-checkbox-box {
    border: 1px solid var(--df-blue);
    background: #fff;
    border-radius: 4px;
    position: relative;
    width: 15px;
    height: 15px;
  }

  &.p-checkbox-checked .p-checkbox-box {
    border: 1px solid $app-color;
    background: $app-color;;

    checkicon {
      color: #fff;

      svg {
        stroke: white;
        stroke-width: 1px;
        height: 11px;
        width: 11px;
        top: 1px;
        position: relative;
      }
    }
  }
}

.p-checkbox-disabled {
  .p-checkbox-box {
    //background-color: var(--light-gray);
    //border-color: var(--light-gray);
    color: var(--df-gray);
  }
}


.warning {
  .p-checkbox {
    .p-checkbox-box {
      background: var(--warning-color-bg);
      border-color: var(--warning-color-bg);

      checkicon {
        color: var(--warning-color);

        svg {
          stroke: var(--warning-color);
        }
      }
    }
  }

  label {
    color: var(--warning-color);
  }
}

.error {
  .p-checkbox {
    .p-checkbox-box {
      background: var(--error-color-bg);
      border-color: var(--error-color-bg);

      checkicon {
        color: var(--error-color);

        svg {
          stroke: var(--error-color);
        }
      }
    }
  }
}


.p-checkbox-box-disabled,
.p-checkbox-box.p-disabled {
  border-color: $gray;
}

.p-checkbox-disabled.p-checkbox-checked {
  .p-checkbox-box {
    color: $dark-gray;
    background-color: $light-gray;
    border-color: $gray;

    .p-icon-wrapper {
      svg {
        stroke: $dark-gray;
      }
    }
  }
}